import { IconType } from "antd/es/notification"
import humps from "humps"

import { Customization } from "~/shared/api/customization"
import { Environment } from "~/shared/api/environment"
import { Org } from "~/shared/api/orgs"
import { DenormalizedTemplate } from "~/shared/api/templates"
import { User } from "~/shared/api/users"
import { DenormalizedWorkspace } from "~/shared/api/workspaces"
import { CanCanAbility } from "~/shared/util/abilities"
import { stringToDayjs } from "~/shared/util/dates"
import { Feature, PermanentFeature } from "~/shared/util/feature"

interface Flash {
  type: IconType
  text: string
}

export interface ViewData {
  user?: User
  flash?: Flash
  [key: string]: any
  org?: Org
  features: { [feature in Feature | PermanentFeature]: boolean }
  // Stores the other Orgs that the login user has access to.
  otherOrgs?: Org[]

  // Only used on /select-organization page to store the possible Orgs
  // the user can access.
  orgs?: Org[]

  // only used on init session through UI
  sessionToken?: string

  root_host: string
}

export type ViewDataFromServer = {
  [key: keyof ViewData]: any // Values may be of a different casing.
}

/**
 * This component is the only interface through which back-end variables passed down
 * as locals should be accessed. As globals, they should be read-only and this
 * component enforces them as such.
 */
class ViewManager {
  private viewData: ViewData

  constructor(data: Record<string, ViewDataFromServer>) {
    this.viewData = this.updateWithData(data)
  }

  // This should only be called externally by the EmbeddedLauncher to
  // reset the viewManager's internal state after initializing the
  // embedded session.
  updateWithData(data: ViewDataFromServer) {
    // Don't camelize the keys of data.features, which are
    // the feature names.
    const { features, ...restData } = data
    this.viewData = humps.camelizeKeys(restData) as ViewData
    this.viewData.features = features as ViewData["features"]

    if (this.viewData.org) {
      const origOrg = this.viewData.org as Omit<Org, "trialEnd"> & {
        trialEnd: string | null
      }
      this.viewData.org.trialEnd =
        origOrg.trialEnd != null ? stringToDayjs(origOrg.trialEnd) : null
    }

    return this.viewData
  }

  // Specify return types for specific keys.
  get(key: "org"): Org | undefined
  get(key: "user"): User | undefined
  get(key: "orgs"): Org[] | undefined
  get(key: "otherOrgs"): Org[] | undefined
  get(key: "features"): { [index in Feature | PermanentFeature]: boolean }
  get(key: "abilities"): CanCanAbility[]
  get(key: "customization"): Customization
  get(key: "sessionToken"): string | undefined
  get(key: "isInternalAdmin"): boolean
  get(key: "stytchSsoConnectionId"): string | undefined
  get(key: "flash"): Flash | undefined
  // Catch-all for unspecified keys.
  get(key: string): any

  get(key: string) {
    if (!this.viewData) {
      return undefined
    }

    return this.viewData[key]
  }

  getOnceThenReadFromStore(key: "templates"): DenormalizedTemplate[]
  getOnceThenReadFromStore(key: "workspaces"): DenormalizedWorkspace[]
  getOnceThenReadFromStore(key: "environments"): Environment[]
  getOnceThenReadFromStore(key: string): any

  getOnceThenReadFromStore(key: string): any {
    return this.get(key)
  }

  updateUser(user: User) {
    this.viewData.user = user
  }
}

const ROOT_HOST = (window.viewData as ViewData).root_host
const NA_ROOT_HOST = "oneschema.co"
export { ROOT_HOST, NA_ROOT_HOST }

export function getEmbedUrlFromRootHost() {
  return `${window.location.protocol}//embed.${ROOT_HOST}`
}

const viewManager = new ViewManager(window.viewData)
export default viewManager

export function showInternalTools(): boolean {
  return viewManager.get("isInternalAdmin")
}
