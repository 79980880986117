import * as Sentry from "@sentry/react"
import { useEffect } from "react"

import { Org } from "~/shared/api/orgs"
import { User } from "~/shared/api/users"
import { loadIntercom } from "~/shared/util/intercom"
import { logEvent } from "~/shared/util/logging"
import { JsonObject } from "~/shared/util/types"
import viewManager, { NA_ROOT_HOST, ROOT_HOST } from "~/shared/util/viewManager"

export function identify(org: Org, user: User, metadata?: Record<string, unknown>) {
  if (process.env.NODE_ENV === "development") {
    logEvent("info", "analytics: identify", { extra: { org, user, metadata } })
    return
  }

  const { name, email, orgId } = user
  const { salesforceId } = org
  const parentOrg = org.parentOrg ?? undefined
  const baseOrg = parentOrg ?? org

  // Fires the intercom boot + identify logic for logged in users
  loadIntercom(user, org)

  const fullstory = (window as any).FS
  if (fullstory) {
    fullstory.identify(String(user.id), {
      displayName: name,
      email,
      orgId,
      orgName: org.name,
      parentOrgId: parentOrg?.id,
      parentOrgName: parentOrg?.name,
      baseOrgId: baseOrg.id,
      baseOrgName: baseOrg.name,
      salesforceId,
      ...metadata,
    })
  }

  const logRocket = (window as any).LogRocket
  if (logRocket) {
    logRocket.identify(String(user.id), {
      name,
      email,
      org_id: orgId,
      org_name: org.name,
      parent_org_id: parentOrg?.id,
      parent_org_name: parentOrg?.name,
      base_org_id: baseOrg.id,
      base_org_name: baseOrg.name,
      salesforce_id: salesforceId,
      ...metadata,
    })
  }

  const posthog = (window as any).posthog
  if (posthog) {
    posthog.identify(String(user.id), {
      name,
      email,
      orgId,
      orgName: org.name,
      parentOrgId: parentOrg?.id,
      parentOrgName: parentOrg?.name,
      baseOrgId: baseOrg.id,
      baseOrgName: baseOrg.name,
      salesforceId,
      ...metadata,
    })
  }

  if (Sentry) {
    Sentry.setUser({ email })

    Sentry.setTag("org", baseOrg.subdomain)

    const embedId = viewManager.get("org")?.embed?.id ?? org.embed?.id
    Sentry.setTag("embedId", embedId)
  }

  const analytics = (window as any).analytics
  if (analytics) {
    // ROOT_HOST looks like eu.oneschema.co or just oneschema.co
    analytics.identify(
      ROOT_HOST === NA_ROOT_HOST ? String(user.id) : `${ROOT_HOST}${String(user.id)}`,
      {
        name,
        email,
        org_id: orgId,
        org_name: org.name,
        parent_org_id: parentOrg?.id,
        parent_org_name: parentOrg?.name,
        base_url: ROOT_HOST,
        base_org_id: baseOrg.id,
        base_org_name: baseOrg.name,
        salesforce_id: salesforceId,
        ...metadata,
      },
    )
  }
}

export function page(params?: string | JsonObject) {
  if (process.env.NODE_ENV === "development") {
    logEvent("info", "analytics: page event", { extra: { params } })
    return
  }

  analytics.page(params)
}

export function track(params?: string | JsonObject) {
  if (process.env.NODE_ENV === "development") {
    logEvent("info", "analytics: track event", { extra: { params } })
    return
  }

  analytics.track(params)
}

export function usePageTracking(pageName: string) {
  useEffect(() => page(pageName), [pageName])
}
